import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const BrandSettings = Loadable(lazy(() => import("src/pages/settings/brand")));
const BusinessSettings = Loadable(
  lazy(() => import("src/pages/settings/business/index"))
);
const NetworkSettings = Loadable(
  lazy(() => import("src/pages/settings/network/index"))
);

const Withdrawal = Loadable(
  lazy(() => import("src/pages/settings/withdrawal/index"))
);
const EmailSettings = Loadable(
  lazy(() => import("src/pages/settings/EmailSetting"))
);

const UserGuidance = Loadable(
  lazy(() => import("src/pages/settings/UserGuidance/Index"))
);
const EditUserGuidance = Loadable(
  lazy(() => import("src/pages/settings/UserGuidance/components/EditForm"))
);
const TermsAndCondition = Loadable(
  lazy(() => import("src/pages/settings/TermsAndCondition/Index"))
);

const settings = [
  {
    path: "settings",
    children: [
      { element: <Navigate to="brand" />, index: true },
      { path: "brand", element: <BrandSettings /> },
      { path: "business", element: <BusinessSettings /> },
      { path: "network", element: <NetworkSettings /> },
      { path: "withdrawal", element: <Withdrawal /> },
      { path: "email_settings", element: <EmailSettings /> },
      { path: "user_guidance", element: <UserGuidance /> },
      { path: ":gid", element: <EditUserGuidance /> },
      { path: "terms&condition", element: <TermsAndCondition /> },
    ],
  },
];

export default settings;
